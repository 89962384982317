import React from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  ENTITY_GROUPS_PATH,
  ENTITY_HOME_PATH,
  ENTITY_ORGANIZATION_PATH,
  ENTITY_SITES_CREATE_PATH,
  ENTITY_SITES_DETAILS_PATH,
  ENTITY_SITES_EDIT_PATH,
  ENTITY_SITES_ELECTRICITY_METERS_CREATE_PATH,
  ENTITY_SITES_GAS_METERS_CREATE_PATH,
  ENTITY_SITES_PATH,
  ENTITY_USERS_PATH
} from 'eficia/constants/paths';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { CheckRouteAccess } from '../CheckRouteAccess';

const SitesHomePage = lazyWithRetry(() => import('eficia/views/entity/SitesHomePage'));
const SitesCreatePage = lazyWithRetry(() => import('eficia/views/entity/SitesCreatePage'));
const SitesEditPage = lazyWithRetry(() => import('eficia/views/entity/SitesEditPage'));
const SitesDetailsPage = lazyWithRetry(() => import('eficia/views/entity/SitesDetailsPage'));
const ElectricityMetersCreatePage = lazyWithRetry(
  () => import('eficia/views/entity/ElectricityMetersCreatePage')
);
const GasMetersCreatePage = lazyWithRetry(() => import('eficia/views/entity/GasMetersCreatePage'));

const GroupsHomePage = lazyWithRetry(() => import('eficia/views/entity/GroupsHomePage'));
const UsersHomePage = lazyWithRetry(() => import('eficia/views/entity/UsersHomePage'));
const InformationsHomePage = lazyWithRetry(
  () => import('eficia/views/entity/InformationsHomePage')
);

const removeRoutePrefix = (path) => {
  const pathPrefix = ENTITY_HOME_PATH;

  return path.replace(pathPrefix, '');
};

export default function EntityRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(ENTITY_SITES_PATH)}
        element={
          <CheckRouteAccess slug="entity_sites">
            <SitesHomePage />
          </CheckRouteAccess>
        }
      />
      <Route path={removeRoutePrefix(ENTITY_SITES_CREATE_PATH)} element={<SitesCreatePage />} />
      <Route path={removeRoutePrefix(ENTITY_SITES_EDIT_PATH)} element={<SitesEditPage />} />
      <Route path={removeRoutePrefix(ENTITY_SITES_DETAILS_PATH)} element={<SitesDetailsPage />} />
      <Route
        path={removeRoutePrefix(ENTITY_SITES_ELECTRICITY_METERS_CREATE_PATH)}
        element={<ElectricityMetersCreatePage />}
      />
      <Route
        path={removeRoutePrefix(ENTITY_SITES_GAS_METERS_CREATE_PATH)}
        element={<GasMetersCreatePage />}
      />

      <Route
        path={removeRoutePrefix(ENTITY_GROUPS_PATH)}
        element={
          <CheckRouteAccess slug="entity_groups">
            <GroupsHomePage />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(ENTITY_USERS_PATH)}
        element={
          <CheckRouteAccess slug="entity_users">
            <UsersHomePage />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(ENTITY_ORGANIZATION_PATH)}
        element={<InformationsHomePage />}
      />
    </Routes>
  );
}
