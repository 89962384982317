import styled, { css } from 'styled-components';

export const ErrorWrapper = styled.div`
  height: 40px;

  ${({ $error }) =>
    $error &&
    css`
      display: block;
      font-size: 12px;
      text-overflow: ellipsis;
      color: red;
      .react-dropdown-tree-select {
        border: 1px solid #f83245;
      }
      .react-dropdown-tree-select > span {
        color: #f83245;
      }

      .tag-item {
        color: #f83245;
      }

      span.placeholder {
        color: #f83245;
      }

      .error {
        color: #f83245;
      }
    `}
`;
