import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { NEWS_DASHBOARD_PATH, NEWS_PATH } from 'eficia/constants/paths';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { CheckRouteAccess } from '../CheckRouteAccess';

const DashboardNewsHomePage = lazyWithRetry(
  () => import('eficia/views/news/DashboardNewsHomePage')
);

const removeRoutePrefix = (path) => {
  const pathPrefix = NEWS_PATH;

  return path.replace(pathPrefix, '');
};

export default function NewsRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(NEWS_DASHBOARD_PATH)}
        element={
          <CheckRouteAccess slug="news">
            <DashboardNewsHomePage />
          </CheckRouteAccess>
        }
      />
    </Routes>
  );
}
