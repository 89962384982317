import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

import useActiveOrganization from 'eficia/hooks/useActiveOrganization';
import useNotification from 'eficia/hooks/useNotification';
import anonymousClient from 'eficia/services/anonymousClient';

export default function useChangeOrganization() {
  const client = anonymousClient();
  const { t } = useTranslation();
  const { addNotification } = useNotification();
  const { changeActiveOrganizationId } = useActiveOrganization();

  const fetcher = useCallback(
    (data) => {
      const params = {
        organizationId: data.organizationId,
        referer: window.location.href
      };

      return client.organization.change(params);
    },
    [client.organization]
  );

  const mutation = useMutation(fetcher, {
    onSuccess: async (response, request) => {
      const { data } = response;

      // Forcer le changement d'organisation active dans tous les autres onglets
      // Mais uniquement dès qu'un onglet est actif à nouveau (éviter de DDoS le Back d'un coup si beaucoup d'onglets)
      changeActiveOrganizationId(request?.organizationId);

      if (data?.data?.redirectUrl) {
        window.location.replace(data?.data?.redirectUrl);
        return;
      }

      // Rafraichir la page à la racine après avoir changé d'organisation
      window.location.replace('/');
    },
    onError: () => {
      addNotification({
        message: t(`organization.select.change.message.error`),
        type: 'error'
      });
    }
  });

  return mutation.mutate;
}
