import axiosConfig from './config/axiosConfig';
import { ENEDIS_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'enedis';

export default function enedisApi(isConnected = false) {
  return {
    async subscribeEnedisService(params) {
      return queryConfig({
        api,
        baseUrl: ENEDIS_API,
        isConnected,
        endpoint: 'subscribeEnedisService',
        params
      });
    },
    async addDevicesToSiteFromUploadedFile(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const query = await axiosConfig.post(
        `${ENEDIS_API}/add-devices-to-site-from-uploaded-file`,
        fd,
        config
      );

      return query;
    }
  };
}
