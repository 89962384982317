import styled from 'styled-components';

export const MultiSelectContainer = styled.div`
  line-height: 1.5;
  border: 1px solid ${({ error, theme }) => (error ? theme.palette.error.main : '#c4c4c4')};
  border-radius: 0.5rem;
  background: white;
  padding: 8px 6px 3px 0px;
  height: 40px;
  display: inline-block;
  width: 100%;
  position: relative;

  &:hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }

  &.showDropdown {
    border-color: var(--text-brand);
    & > span {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  input::placeholder {
    color: ${({ error, theme }) => (error ? theme.palette.error.main : theme.palette.grey[600])};
  }
`;

export const Label = styled.span`
  position: absolute;
  top: -9px;
  left: 9px;
  padding: 0 4px;
  font-size: 11px;
  font-weight: 400;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 5px;
`;
