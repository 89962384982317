import React, { useContext, useRef } from 'react';
import { Paper, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

import { Button } from 'eficia/components/atoms/Button';
import TooltipIcon from 'eficia/components/atoms/Tooltip/TooltipIcon';
import Page from 'eficia/components/organisms/Page/Page';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';

export default function EmptyPage({
  htmlTitle,
  to,
  buttonLabel,
  buttonIcon = <i className="fa-solid fa-plus" />,
  background,
  content,
  allowed = false,
  disallowedContent = '',
  onClick,
  helpTooltip,
  ...rest
}) {
  const { isPageTitlePresent } = useContext(LayoutContext);

  const navigate = useNavigate();

  const container = useRef(null);

  function handleRedirect() {
    navigate(to);
  }

  return (
    <Page htmlTitle={htmlTitle}>
      <div
        className="d-flex justify-content-center align-items-center"
        ref={container}
        style={{
          position: 'absolute',
          top: isPageTitlePresent ? -90 : 0,
          left: 0,
          width: '100%',
          height: '100vh',
          backgroundImage: `url(${background})`,
          backgroundSize: 'cover'
        }}
        {...rest}
      >
        <Paper
          elevation={3}
          container={container.current}
          style={{
            minHeight: buttonLabel ? 230 : 80,
            textAlign: 'center',
            maxWidth: 520,
            margin: '0 24px'
          }}
          className="p-5 d-flex justify-content-center align-items-center flex-column flex-wrap"
        >
          <div className="d-flex flex-row" style={{ gap: 8 }}>
            <Typography variant="body2">{allowed ? content : disallowedContent}</Typography>

            {helpTooltip && (
              <TooltipIcon
                title={helpTooltip.title}
                content={helpTooltip.content}
                link={helpTooltip.link}
              />
            )}
          </div>
          {buttonLabel && allowed && (
            <Button
              style={{ marginTop: 12 }}
              startIcon={buttonIcon}
              label={buttonLabel}
              onClick={onClick || handleRedirect}
            />
          )}
        </Paper>
      </div>
    </Page>
  );
}

EmptyPage.propTypes = {
  onClick: PropTypes.func,
  allowed: PropTypes.bool,
  disallowedContent: PropTypes.string,
  content: PropTypes.string.isRequired,
  htmlTitle: PropTypes.string.isRequired,
  to: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonIcon: PropTypes.element,
  background: PropTypes.string,
  helpTooltip: PropTypes.object
};
