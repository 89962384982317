import React, { useEffect, useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import { Modal } from 'eficia/components/organisms/Modal';

function CalendlyModal({ calendlyUrl, onClose }) {
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const { t } = useTranslation();

  // On stocke la valeur de isTablet au moment du montage du composant
  // Ceci afin d'éviter de re-render l'iframe (et donc perdre la saisi de l'utilisateur) si la valeur de isTablet change (rotation de l'écran par exemple)
  const [isTabletAtMount, setIsTabletAtMount] = useState(isTablet);

  // L'iframe ne s'affiche pas correctement sur les petits écrans
  // On fixe donc la hauteur de l'iframe  à toute la hauteur de l'écran moins la modal
  const [iframeHeight, setIframeHeight] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
    };
    handleResize();

    // On écoute le resize de la fenêtre pour recalculer la hauteur de l'iframe
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIframeHeight(
      isTabletAtMount
        ? viewportHeight -
            100 /* titre de la modale + marge du haut */ -
            100 /* footer de la modale + marge du bas */
        : viewportHeight - 32 /* marge du haut */ - 32 /* marge du bas */
    );
  }, [isTabletAtMount, viewportHeight]);

  const handleOnClose = () => {
    setIsOpen(false);
    onClose();
  };

  const handleLoader = () => {
    return <div />;
  };

  return isTabletAtMount ? (
    /* Tablette et mobile */
    <Modal
      onSubmit={() => {}}
      headerTitle={t('calendly.modal.title')}
      cancelLabel={t('calendly.modal.close')}
      onClose={onClose}
      isModalOpen={isOpen}
      isModalContentFullWidth
      testId="calendly-modal-tablet"
    >
      <InlineWidget url={calendlyUrl} styles={{ height: `${iframeHeight}px` }} />
    </Modal>
  ) : (
    /* Desktop */
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      scroll="body"
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          margin: 0,
          padding: 0,
          maxWidth: '1000px',
          overflow: 'hidden'
        }
      }}
      data-testid="calendly-modal-desktop"
    >
      <IconButton
        sx={(theme) => ({
          position: 'absolute',
          right: 24,
          top: 24,
          color: theme.palette.grey[200]
        })}
        aria-label="close"
        color="inherit"
        size="large"
        onClick={handleOnClose}
      >
        <i className="fa-solid fa-xmark" />
      </IconButton>

      <InlineWidget
        LoadingSpinner={handleLoader}
        url={calendlyUrl}
        styles={{
          minHeight: 800,
          height: `${iframeHeight}px`,
          width: '100%',
          overflow: 'hidden'
        }}
      />
    </Dialog>
  );
}

CalendlyModal.propTypes = {
  calendlyUrl: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default CalendlyModal;
