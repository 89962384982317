/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/label-has-associated-control */
import InfiniteScroll from 'react-infinite-scroll-component';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { useTranslation } from 'react-i18next';

import TreeNode from '../tree-node';
import TreeSubHeader from '../tree-node/NodeSubHeader';
import { findIndex } from '../utils';
import Checkbox from '../checkbox';

const shouldRenderNode = (node, searchModeOn, data) => {
  if (searchModeOn || node.expanded) return true;

  const parent = node._parent && data.get(node._parent);
  // if it has a parent, then check parent's state.
  // otherwise root nodes are always rendered
  return !parent || parent.expanded;
};

// Workaround afin d'avoir le hook de traduction dans un composant de classe
function Translation({ translationKey }) {
  const { t } = useTranslation();

  return <>{t(translationKey)}</>;
}
Translation.propTypes = {
  translationKey: PropTypes.string.isRequired
};

class Tree extends Component {
  constructor(props) {
    super(props);
    const { pageSize, pagination } = this.props;
    this.currentPage = 1;
    this.computeInstanceProps(props, true);

    this.state = {
      items: pagination ? this.allVisibleNodes : this.allVisibleNodes.slice(0, pageSize)
    };
  }

  componentDidMount() {
    this.setState({ scrollableTarget: this.node.parentNode });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { activeDescendant } = nextProps;
    const { pageSize, activeDescendant: activeDescendantProps, pagination } = this.props;
    const hasSameActiveDescendant = activeDescendant === activeDescendantProps;
    this.computeInstanceProps(nextProps, !hasSameActiveDescendant);

    this.setState({
      items: pagination
        ? this.allVisibleNodes
        : this.allVisibleNodes.slice(0, this.currentPage * pageSize)
    });
  }

  computeInstanceProps = (props, checkActiveDescendant) => {
    const { pageSize } = this.props;
    this.allVisibleNodes = this.getNodes(props);
    this.totalPages = Math.ceil(this.allVisibleNodes.length / pageSize);
    if (checkActiveDescendant && props.activeDescendant) {
      const currentId = props.activeDescendant.replace(/_li$/, '');
      const focusIndex = findIndex(this.allVisibleNodes, (n) => n.key === currentId) + 1;
      this.currentPage = focusIndex > 0 ? Math.ceil(focusIndex / pageSize) : 1;
    }
  };

  getNodes = ({ data }) => {
    const {
      keepTreeOnSearch,
      keepChildrenOnSearch,
      searchModeOn,
      mode,
      readOnly,
      onAction,
      onChange,
      onCheckboxChange,
      onNodeToggle,
      activeDescendant,
      clientId,
      dataTestId
    } = this.props;
    const items = [];

    data.forEach((node) => {
      if (shouldRenderNode(node, searchModeOn, data)) {
        items.push(
          node.isSubHeader ? (
            <TreeSubHeader key={node._id} dataTestId={dataTestId} {...node} />
          ) : (
            <TreeNode
              keepTreeOnSearch={keepTreeOnSearch}
              keepChildrenOnSearch={keepChildrenOnSearch}
              key={`${node._id}_${node.value}_${node.checked}`}
              dataTestId={dataTestId}
              {...node}
              searchModeOn={searchModeOn}
              onChange={onChange}
              onCheckboxChange={onCheckboxChange}
              onNodeToggle={onNodeToggle}
              onAction={onAction}
              mode={mode}
              readOnly={readOnly}
              clientId={clientId}
              activeDescendant={activeDescendant}
            />
          )
        );
      }
    });
    return items;
  };

  hasMore = () => {
    const { pagination } = this.props;

    if (pagination) {
      return pagination.currentPage < pagination.lastPage;
    }

    return this.currentPage < this.totalPages;
  };

  loadMore = () => {
    const { pageSize } = this.props;
    this.currentPage += 1;
    const nextItems = this.allVisibleNodes.slice(0, this.currentPage * pageSize);
    this.setState({ items: nextItems });
  };

  setNodeRef = (node) => {
    this.node = node;
  };

  getAriaAttributes = () => {
    const { mode } = this.props;

    const attributes = {
      /* https://www.w3.org/TR/wai-aria-1.1/#select
       * https://www.w3.org/TR/wai-aria-1.1/#tree */
      role: mode === 'simpleSelect' ? 'listbox' : 'tree',
      'aria-multiselectable': /multiSelect|hierarchical/.test(mode)
    };

    return attributes;
  };

  render() {
    const {
      withSelectAll = true,
      searchModeOn,
      onSelectAllClick,
      selectAllChecked,
      mode,
      loadMore,
      dataTestId
    } = this.props;
    const { scrollableTarget, items } = this.state;

    return (
      <ul
        className={`root ${searchModeOn ? 'searchModeOn' : ''}`}
        ref={this.setNodeRef}
        {...this.getAriaAttributes()}
      >
        {scrollableTarget && (
          <InfiniteScroll
            height={this.hasMore() ? 260 : 'auto'}
            style={{ maxHeight: 260 }}
            dataLength={items.length}
            next={loadMore || this.loadMore}
            hasMore={this.hasMore()}
            loader={
              <div className="searchLoader">
                <span style={{ fontSize: '14px', color: 'var(--text-placeholder)' }}>
                  <Translation translationKey="multiselect.loading" />
                </span>
              </div>
            }
            scrollableTarget={scrollableTarget}
          >
            {mode !== 'simpleSelect' && withSelectAll && (
              <label htmlFor="selectAll" className="selectAllWrapper">
                <span style={{ fontSize: '14px', color: 'var(--text-brand)' }}>
                  <Translation translationKey="multiselect.select_all" />
                </span>
                <Checkbox
                  id="selectAll"
                  name="selectAll"
                  checked={selectAllChecked}
                  onChange={onSelectAllClick}
                  data-testid={dataTestId ? `${dataTestId}-select-all` : undefined}
                />
              </label>
            )}
            {items}
          </InfiniteScroll>
        )}
      </ul>
    );
  }
}

Tree.propTypes = {
  data: PropTypes.object,
  keepTreeOnSearch: PropTypes.bool,
  keepChildrenOnSearch: PropTypes.bool,
  searchModeOn: PropTypes.bool,
  onChange: PropTypes.func,
  onNodeToggle: PropTypes.func,
  onAction: PropTypes.func,
  onCheckboxChange: PropTypes.func,
  mode: PropTypes.oneOf(['multiSelect', 'simpleSelect']),
  pageSize: PropTypes.number,
  loadMore: PropTypes.func,
  pagination: PropTypes.exact({
    itemCount: PropTypes.number.isRequired,
    lastPage: PropTypes.number,
    perPage: PropTypes.number,
    currentPage: PropTypes.number
  }),
  readOnly: PropTypes.bool,
  clientId: PropTypes.string,
  activeDescendant: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  // TODO: Résoudre le type en node
  dropdownNode: PropTypes.object,
  dataTestId: PropTypes.string,
  onSelectAllClick: PropTypes.func,
  selectAllChecked: PropTypes.bool,
  withSelectAll: PropTypes.bool
};

Tree.defaultProps = {
  pageSize: 100
};

export default Tree;
