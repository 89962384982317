import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { AccessContext } from 'eficia/contexts/AccessProvider';
import { UserContext } from 'eficia/contexts/UserProvider';
import ForbiddenPageAccess from 'eficia/views/error/ForbiddenPageAccess';
import { NAV_CONFIG } from 'eficia/views/template/Sidebar/constants';

// Helper permettant de retrouver un élément du menu à partir de son slug (récursif)
export const findMenuItemBySlug = (slug, subMenus = null) => {
  const menus = subMenus || NAV_CONFIG;
  // eslint-disable-next-line
  for (const menuItem of menus) {
    if (menuItem.slug === slug) {
      return menuItem;
    }
    if (menuItem.content) {
      const subMenuItem = findMenuItemBySlug(slug, menuItem.content);
      if (subMenuItem) {
        return subMenuItem;
      }
    }
  }
  return null;
};

export const CheckRouteAccess = ({ slug, children }) => {
  const [isAuthorized, setIsAuthorized] = useState(null);

  const { groupNotFound, userData, userSidebarPermissions } = useContext(UserContext);
  const { allowedAccesses } = useContext(AccessContext);

  useEffect(() => {
    if (!userSidebarPermissions?.length) {
      // On attend que les permissions soient chargées
      return;
    }

    const menuItem = findMenuItemBySlug(slug);
    if (!menuItem) {
      // Erreur lors du développement d'une nouvelle page
      console.error(
        `No menu item found for slug: "${slug}", please check the Sidebar's configuration`
      );
      // À décider si l'on bloque le développeur le temps de lire le console.error afin de corriger son `slug`
      setIsAuthorized(false);
      return;
    }

    if (
      menuItem.hasAccess &&
      !menuItem.hasAccess({
        groupNotFound,
        userData,
        userSidebarPermissions,
        allowedAccesses,
        isProduction: process.env.REACT_APP_EFICIA_ENVIRONMENT === 'production'
      })
    ) {
      // Le `hasAccess` de ce menu n'est pas satisfait
      setIsAuthorized(false);
      return;
    }

    setIsAuthorized(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSidebarPermissions]);

  if (isAuthorized === null) {
    // Pas encore prêt
    return;
  }
  if (!isAuthorized) {
    // L'accès est refusé
    return <ForbiddenPageAccess />;
  }
  // L'accès est autorisé
  return children;
};

CheckRouteAccess.propTypes = {
  slug: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};
