import React from 'react';
import PropTypes from 'prop-types';

import { BadgeContainer } from './Badge.style';

export default function Badge({ size = 'small', color = 'tertiary', label, styles = {}, ...rest }) {
  const colors = {
    tertiary: {
      backgroundColor: 'var(--bg-tertiary)',
      color: 'var(--text-body)',
      borderColor: 'var(--text-body)'
    },
    success: {
      backgroundColor: 'var(--bg-success-low)',
      color: 'var(--text-success)',
      borderColor: 'var(--border-success)'
    },
    error: {
      backgroundColor: 'var(--bg-error-low)',
      color: 'var(--text-error)',
      borderColor: 'var(--border-error)'
    }
  };

  const defaultStyles = {
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    height: '18px',
    padding: '1px 4px 0 4px',
    backgroundColor: colors[color]?.backgroundColor || 'transparent',
    color: colors[color]?.color || 'var(--text-body)'
  };

  if (size === 'medium') {
    defaultStyles.fontSize = '14px';
    defaultStyles.lineHeight = '18px';
    defaultStyles.height = '27px';
    defaultStyles.padding = '4px 8px 0 8px';
    defaultStyles.border = `1px solid ${colors[color]?.borderColor || 'transparent'}`;
  }

  return (
    <BadgeContainer style={{ ...defaultStyles, ...styles }} {...rest}>
      {label}
    </BadgeContainer>
  );
}

Badge.propTypes = {
  /** Size of the badge */
  size: PropTypes.oneOf(['small', 'medium']),
  /** Color of the badge */
  color: PropTypes.oneOf(['tertiary', 'success', 'error']),
  /** Text to display */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** Override styles */
  styles: PropTypes.object
};
