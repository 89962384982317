import styled from 'styled-components';
import { Button } from '@mui/material';

export const SidebarButtonContainer = styled(Button)`
  display: flex;
  margin: 12px;
  height: 64px;
  gap: 12px;
`;

export const SidebarProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 4px;
  width: 160px;

  & > strong {
    display: flex;
    color: var(--text-body);
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 170px;
    white-space: nowrap;
  }

  & > span {
    display: flex;
    gap: 4px;
    color: ${({ theme }) => theme.palette.textAction};
    text-align: left;
    align-items: end;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
`;
