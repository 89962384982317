import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import { LayoutContext } from 'eficia/contexts/LayoutProvider';

function Page({ htmlTitle, isEmptyPagePresent = false, children }) {
  const { isEmptyViewPresent } = useContext(LayoutContext);

  useEffect(() => {
    document.title = htmlTitle || 'WebApp Eficia';
  }, [htmlTitle]);

  return (
    <div
      style={{
        paddingBottom: isEmptyPagePresent || isEmptyViewPresent ? 0 : 90
      }}
    >
      {children}
    </div>
  );
}

Page.propTypes = {
  htmlTitle: PropTypes.string,
  isEmptyPagePresent: PropTypes.bool,
  children: PropTypes.node
};

export default Page;
