import { useEffect, useState } from 'react';

const SESSION_ACTIVE_ORGANIZATION_ID_KEY = 'activeOrganizationId';

// Hook permettant de n'avoir qu'une seule organisation active
// Et ainsi éviter que l'utilisateur ait plusieurs onglets ouverts avec des organisations différentes
export default function useActiveOrganization(organizationId) {
  const [tabOrganizationId, setTabOrganizationId] = useState(organizationId);

  // Supprimer l'organisation active de la session
  // Ceci afin de repartir sur une base vierge (et éviter ainsi de potentielle boucles de redirection)
  const changeActiveOrganizationId = (newOrganizationId = null) => {
    localStorage.setItem(SESSION_ACTIVE_ORGANIZATION_ID_KEY, newOrganizationId || Date.now());
  };

  useEffect(() => {
    if (organizationId) {
      changeActiveOrganizationId(organizationId);

      setTabOrganizationId(organizationId);
    }
  }, [organizationId]);

  useEffect(() => {
    if (!tabOrganizationId) {
      return;
    }

    const handleVisibilityChange = () => {
      if (document.hidden) {
        // L'onglet est caché
        return;
      }

      if (!tabOrganizationId) {
        // L'organisation n'est pas/plus (encore) définie (l'appel à `/me` est asynchrone)
        return;
      }

      const sessionActiveOrganizationId = localStorage.getItem(SESSION_ACTIVE_ORGANIZATION_ID_KEY);
      if (!sessionActiveOrganizationId) {
        // L'organisation n'est pas/plus (encore) définie dans le localStorage
        return;
      }
      if (sessionActiveOrganizationId === tabOrganizationId) {
        // Même organisation à travers les onglets
        return;
      }

      // L'organisation dans cet onglet est différente de l'organisation de la session

      // Redirection vers la page d'accueil afin de recharger les données dans la bonne organisation
      window.location.replace('/');
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [tabOrganizationId]);

  return {
    changeActiveOrganizationId
  };
}
