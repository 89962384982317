import styled from 'styled-components';

export const OrganizationTitle = styled.div`
  color: var(--text-body);
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  margin: 10px 16px 10px 16px;
`;

export const OrganizationItem = styled.a`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 0 18px 0 16px;
  width: 100%;

  &.selected,
  &:hover {
    background-color: #f0f9ff;
  }
`;

export const OrganizationName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
  white-space: nowrap;
`;
