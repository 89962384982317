import { DATADIS_API } from './config/constants';
import queryConfig from './config/queryConfig';

const api = 'datadis';
const baseUrl = DATADIS_API;

export default function datadisApi(isConnected = false) {
  return {
    async addUnvalidatedMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'addUnvalidatedMeter',
        params
      });
    },
    async fetchUnvalidatedMeters(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchUnvalidatedMeters',
        params
      });
    },
    async fetchOperatingAuthorizationFormURL(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'fetchOperatingAuthorizationFormURL',
        params
      });
    },
    async checkNifAuthorization(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'checkNifAuthorization',
        params
      });
    },
    async deleteUnvalidatedMeter(params) {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'deleteUnvalidatedMeter',
        params
      });
    },
    async exportMetersToUpdateFile() {
      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'exportMetersToUpdateFile',
        params: {},
        config: {
          responseType: 'blob'
        }
      });
    },
    async uploadMetersToUpdateFile(params) {
      const fd = new FormData();
      fd.append('file', params.file);

      return queryConfig({
        api,
        isConnected,
        baseUrl,
        endpoint: 'uploadMetersToUpdateFile',
        params: fd,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      });
    }
  };
}
