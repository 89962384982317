import React from 'react';
import { useTranslation } from 'react-i18next';

import EmptyPage from 'eficia/components/organisms/Page/EmptyPage';
import CurveEmptyBackground from 'assets/eficia/images/curve-empty.jpg';

export default function ForbiddenPageAccess() {
  const { t } = useTranslation();

  return (
    <EmptyPage
      background={CurveEmptyBackground}
      htmlTitle={t('forbidden_page_access.title')}
      content={t('forbidden_page_access.content')}
      allowed
      buttonIcon={null}
      buttonLabel={t('forbidden_page_access.button')}
      to="/"
      data-testid="forbidden-page-access"
    />
  );
}
