import React from 'react';
import PropTypes from 'prop-types';
import { ListItem } from '@mui/material';

import { MenuItemIcon, MenuItemText } from './MenuItem.style';

export default function MenuItem({ children, icon, ...rest }) {
  return (
    <ListItem button {...rest}>
      {icon && <MenuItemIcon>{icon}</MenuItemIcon>}
      <MenuItemText>{children}</MenuItemText>
    </ListItem>
  );
}

MenuItem.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.node
};
