import React from 'react';
import { Route, Routes } from 'react-router-dom';

import EquipmentsIcon from 'assets/eficia/icons/equipments.svg';
import {
  AIR_CURVE_SITE_HOME_PATH,
  BASE_SITE_PATH,
  BAS_ACCESS_SITE_HOME_PATH,
  DASHBOARD_SITE_HOME_PATH,
  DEPRECATED_CURVE_SITE_HOME_PATH,
  DEPRECATED_HARMONY_CURVE_SITE_HOME_PATH,
  DEPRECATED_HARMONY_PILOT_SITE_HOME_PATH,
  ELECTRICITY_ACCOUNTING_SITE_HOME_PATH,
  ELECTRICITY_CURVE_SITE_HOME_PATH,
  GAS_ACCOUNTING_SITE_HOME_PATH,
  GAS_CURVE_SITE_HOME_PATH,
  HVAC_CURVE_SITE_HOME_PATH,
  MISCELLANEOUS_SITE_HOME_PATH,
  OTHER_EQUIPMENTS_SITE_HOME_PATH,
  PRO_AIR_CURVE_SITE_HOME_PATH,
  PRO_BAS_ACCESS_SITE_HOME_PATH,
  WATER_ACCOUNTING_SITE_HOME_PATH,
  WATER_CURVE_SITE_HOME_PATH
} from 'eficia/constants/paths';
import { lazyWithRetry } from 'eficia/utilities/lazyWithRetry';

import { CheckRouteAccess } from '../CheckRouteAccess';

const DashboardSiteHomePage = lazyWithRetry(
  () => import('eficia/views/site/DashboardSiteHomePage')
);
const ElectricityCurveHomePage = lazyWithRetry(
  () => import('eficia/views/site/ElectricityCurveHomePage')
);
const GasCurveHomePage = lazyWithRetry(() => import('eficia/views/site/GasCurveHomePage'));
const AirCurveHomePage = lazyWithRetry(() => import('eficia/views/site/AirCurveHomePage'));
const WaterCurveHomePage = lazyWithRetry(() => import('eficia/views/site/WaterCurveHomePage'));
const HarmonyPilotHomePage = lazyWithRetry(() => import('eficia/views/site/HarmonyPilotHomePage'));
const ProAirCurveHomePage = lazyWithRetry(() => import('eficia/views/site/ProAirCurveHomePage'));
const ProHarmonyPilotHomePage = lazyWithRetry(
  () => import('eficia/views/site/ProHarmonyPilotHomePage')
);

const DataVizSitePage = lazyWithRetry(() => import('eficia/views/dataviz/DataVizSitePage'));
const RedirectPage = lazyWithRetry(() => import('eficia/views/error/RedirectPage'));

const removeRoutePrefix = (path) => {
  const pathPrefix = BASE_SITE_PATH;

  return path.replace(pathPrefix, '');
};

export default function SiteRoutes() {
  return (
    <Routes>
      <Route
        path={removeRoutePrefix(DASHBOARD_SITE_HOME_PATH)}
        element={<DashboardSiteHomePage />}
      />
      <Route
        path={removeRoutePrefix(BAS_ACCESS_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="bas_access">
            <HarmonyPilotHomePage />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(ELECTRICITY_CURVE_SITE_HOME_PATH)}
        element={<ElectricityCurveHomePage />}
      />
      <Route path={removeRoutePrefix(GAS_CURVE_SITE_HOME_PATH)} element={<GasCurveHomePage />} />
      <Route
        path={removeRoutePrefix(WATER_CURVE_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="water_curve_site">
            <WaterCurveHomePage />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(AIR_CURVE_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="air_curve_site">
            <AirCurveHomePage />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(PRO_AIR_CURVE_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="pro_air_curve_site">
            <ProAirCurveHomePage />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(PRO_BAS_ACCESS_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="pro_bas_access">
            <ProHarmonyPilotHomePage />
          </CheckRouteAccess>
        }
      />

      <Route
        path={removeRoutePrefix(HVAC_CURVE_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="hvac_curve_site">
            <DataVizSitePage
              access="sites.equipments.hvac"
              icon={<img src={EquipmentsIcon} alt="" />}
              title="site.view.equipments_hvac.title"
            />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(OTHER_EQUIPMENTS_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="other_equipments_site">
            <DataVizSitePage
              access="sites.equipments.other"
              icon={<img src={EquipmentsIcon} alt="" />}
              title="site.view.equipments_others.title"
            />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(ELECTRICITY_ACCOUNTING_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="electricity_accounting_site">
            <DataVizSitePage
              access="sites.accounting.electricity"
              icon="file-lines"
              title="site.view.accounting_electricity.title"
            />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(GAS_ACCOUNTING_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="gas_accounting_site">
            <DataVizSitePage
              access="sites.accounting.gas"
              icon="file-lines"
              title="site.view.accounting_gas.title"
            />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(WATER_ACCOUNTING_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="water_accounting_site">
            <DataVizSitePage
              access="sites.accounting.water"
              icon="file-lines"
              title="site.view.accounting_water.title"
            />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(MISCELLANEOUS_SITE_HOME_PATH)}
        element={
          <CheckRouteAccess slug="miscellaneous_site">
            <DataVizSitePage
              access="sites.miscellaneous"
              icon="ellipsis"
              title="site.view.miscellaneous.title"
            />
          </CheckRouteAccess>
        }
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_CURVE_SITE_HOME_PATH)}
        element={
          <RedirectPage
            to={({ searchObj, stringifyQuery }) => {
              if (searchObj?.currentTab === 'gas') {
                return `${GAS_CURVE_SITE_HOME_PATH}${stringifyQuery({
                  ...searchObj,
                  currentTab: undefined
                })}`;
              }
              return `${ELECTRICITY_CURVE_SITE_HOME_PATH}${stringifyQuery({
                ...searchObj,
                currentTab: undefined
              })}`;
            }}
          />
        }
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_HARMONY_CURVE_SITE_HOME_PATH)}
        element={<RedirectPage to={() => AIR_CURVE_SITE_HOME_PATH} />}
      />
      <Route
        path={removeRoutePrefix(DEPRECATED_HARMONY_PILOT_SITE_HOME_PATH)}
        element={<RedirectPage to={() => BAS_ACCESS_SITE_HOME_PATH} />}
      />
    </Routes>
  );
}
