import React from 'react';
import PropTypes from 'prop-types';

import { MenuWrapper } from './Menu.style';

export default function Menu({ anchorElement, children, onClose, position = 'center' }) {
  return (
    <MenuWrapper
      className="menu-wrapper"
      anchorEl={anchorElement}
      open={!!anchorElement}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: position
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: position
      }}
      MenuListProps={{
        'data-testid': 'menu-items'
      }}
    >
      {children}
    </MenuWrapper>
  );
}

Menu.propTypes = {
  anchorElement: PropTypes.object,
  children: PropTypes.node,
  onClose: PropTypes.func,
  position: PropTypes.string
};
