import React, { useState } from 'react';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Tooltip from './Tooltip';
import { IconContainer } from './Tooltip.style';

export default function TooltipIcon({ title, content, link, icon = 'information', ...rest }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  function handleClose() {
    if (isMobile) {
      setIsOpen(false);
    }
  }
  function handleOpen() {
    if (isMobile) {
      setIsOpen(true);

      setTimeout(() => {
        handleClose();
      }, 8000);
    }
  }

  const tooltipDisplay = (
    <div className="d-flex flex-column" style={{ fontWeight: 'normal', gap: 8 }}>
      {title && <div style={{ fontWeight: '500', fontSize: 16 }}>{title}</div>}
      <div>{content}</div>
      {link && (
        <div>
          <a
            style={{ color: 'var(--inverted-low)', textDecoration: 'underline' }}
            target="_blank"
            href={link}
          >
            {t('tooltip.see_more')}
          </a>
        </div>
      )}
    </div>
  );

  let iconDisplay;
  if (icon === 'information') {
    iconDisplay = <i className="fa-regular fa-circle-question" />;
  } else if (icon === 'information-warning') {
    iconDisplay = (
      <i
        className="fa-regular fa-circle-question"
        style={{ color: 'var(--text-warning)', width: '12px' }}
      />
    );
  } else if (icon === 'exclamation-danger') {
    iconDisplay = (
      <i
        className="fa-regular fa-triangle-exclamation"
        style={{ color: 'var(--text-danger)', width: '12px' }}
      />
    );
  }

  return isMobile ? (
    <div>
      <Tooltip
        title={<ClickAwayListener onClickAway={handleClose}>{tooltipDisplay}</ClickAwayListener>}
        placement="right"
        disableTouchListener={isMobile}
        open={isMobile ? isOpen : undefined}
        onClose={handleClose}
        {...rest}
      >
        <IconContainer onClick={handleOpen}>{iconDisplay}</IconContainer>
      </Tooltip>
    </div>
  ) : (
    <Tooltip
      title={tooltipDisplay}
      placement="right"
      enterTouchDelay={0}
      leaveTouchDelay={1500}
      {...rest}
    >
      <IconContainer onClick={handleOpen}>{iconDisplay}</IconContainer>
    </Tooltip>
  );
}

TooltipIcon.propTypes = {
  title: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  link: PropTypes.string,
  icon: PropTypes.oneOf(['information', 'information-warning', 'exclamation-danger'])
};
