import { useEffect } from 'react';

const AUTO_REFRESH_INTERVAL = 30 * 60 * 1000; // 30 minutes
const INACTIVITY_THRESHOLD = 24 * 60 * 60 * 1000; // 24 heures

// SOURCE: ChatGPT +
export default function useAutoRefresh() {
  useEffect(() => {
    const updateLastActivity = () => {
      localStorage.setItem('lastActivity', Date.now());
    };

    const checkInactivity = () => {
      const lastActivity = parseInt(localStorage.getItem('lastActivity') || '0');
      const now = Date.now();

      if (now - lastActivity > INACTIVITY_THRESHOLD) {
        window.location.reload();
      }
    };

    // Initialiser la dernière activité
    updateLastActivity();

    // Vérifier l'inactivité toutes les 30 minutes
    const intervalId = setInterval(checkInactivity, AUTO_REFRESH_INTERVAL);

    // Vérifier lorsque l'onglet redevient actif
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        checkInactivity();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(intervalId);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  return null;
}
