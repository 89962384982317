const Typography = {
  fontFamily: 'Cabin, Roboto, sans-serif',
  fontSize: 14,
  h1: {
    fontSize: '18px',
    fontWeight: 400,
    color: '#2D4F77'
  },
  h2: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#2D4F77'
  },
  h3: {
    fontSize: '16px',
    fontWeight: 700,
    color: '#2D4F77'
  },
  h4: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '18px'
  },
  h5: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px'
  },
  h6: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  subtitle1: {
    fontSize: '15px',
    color: '#475569',
    lineHeight: 1.1,
    fontWeight: 400,
    height: 22
  },
  subtitle2: {
    fontSize: '14px',
    color: '#475569',
    fontWeight: 400
    // fontWeight: 700,
    // fontSize: '13.3px',
    // lineHeight: '20px',
    // letterSpacing: '0.2px'
    // textTransform: 'uppercase'
  },
  body1: {
    fontSize: '16px',
    color: '#475569',
    fontWeight: 400
  },
  body2: {
    fontSize: '18px',
    color: '#475569',
    fontWeight: 400
  },
  button: {
    fontSize: '13px',
    letterSpacing: '0.4px',
    lineHeight: '24.5px',
    fontWeight: 400
  },
  caption: {
    fontSize: '12px'
  },
  overline: {
    fontSize: '10px',
    fontWeight: 500,
    lineHeight: '19px',
    letterSpacing: '1px'
  }
};

export default Typography;
