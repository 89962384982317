import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import { PROFILE_EDIT_PATH, PROFILE_HOME_PATH } from 'eficia/constants/paths';
import { LayoutContext } from 'eficia/contexts/LayoutProvider';
import { UserContext } from 'eficia/contexts/UserProvider';

import { Footer } from '../Footer';
import { Header } from '../Header';
import { Sidebar } from '../Sidebar';

import {
  AppContent,
  AppContentInner,
  AppContentWrapper,
  AppMain,
  AppWrapper
} from './LeftSidebar.style';

function LeftSidebar({ children }) {
  const withoutVisibleSidebar = useMediaQuery((theme) => theme.breakpoints.down('lg'));
  const [sidebarToggleMobile, setSidebarToggleMobile] = useState(false);
  const [hideSidebarContent, setHideSidebarContent] = useState(false);

  const { pathname } = useLocation();

  const { setIsSidebarPresent } = useContext(LayoutContext);

  const { organizationNotFound } = useContext(UserContext);

  useEffect(() => {
    setIsSidebarPresent(true);
    return () => {
      setIsSidebarPresent(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ([PROFILE_HOME_PATH, PROFILE_EDIT_PATH].includes(pathname)) {
      if (organizationNotFound) {
        setHideSidebarContent(true);
        return;
      }
    }

    if (['/is-unknown', '/organization-not-found'].includes(pathname)) {
      setHideSidebarContent(true);
      return;
    }

    setHideSidebarContent(false);
  }, [organizationNotFound, pathname]);

  return (
    <AppWrapper>
      <Header
        sidebarToggleMobile={sidebarToggleMobile}
        setSidebarToggleMobile={setSidebarToggleMobile}
      />
      <AppMain>
        <Sidebar
          sidebarToggleMobile={sidebarToggleMobile}
          setSidebarToggleMobile={setSidebarToggleMobile}
          hideSidebarContent={hideSidebarContent}
        />
        <AppContent>
          <AppContentInner>
            <AppContentWrapper
              style={{
                marginLeft: withoutVisibleSidebar ? 0 : 264,
                width: withoutVisibleSidebar ? '100vw' : '100%'
              }}
            >
              {children}
            </AppContentWrapper>
          </AppContentInner>
          <Footer />
        </AppContent>
      </AppMain>
    </AppWrapper>
  );
}

LeftSidebar.propTypes = {
  children: PropTypes.node
};

export default LeftSidebar;
