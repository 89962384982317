import React, { useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import PropTypes from 'prop-types';

import Badge from 'eficia/components/atoms/Badge/Badge';
import { UserContext } from 'eficia/contexts/UserProvider';
import GroupsFormMultiSelect from 'eficia/views/lists/GroupsFormMultiSelect';
import SitesFormSingleSelect from 'eficia/views/lists/SitesFormSingleSelect';

import { AND_OPERATOR, OR_OPERATOR, operators } from './constants';

export default function SidebarFilter({ filterTab }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const { organizationId, userOperator, changeUserFilters, userFilters } = useContext(UserContext);
  const [cookies, setCookie] = useCookies();

  const [operator, setOperator] = useState(userOperator);

  const formProps = useForm({
    defaultValues: {
      groupIds: userFilters.groupIds || [],
      siteId: userFilters.siteId || ''
    }
  });
  const { control, watch, setValue } = formProps;

  const groupIds = watch('groupIds');
  const siteId = watch('siteId');

  useEffect(() => {
    if (siteId && siteId !== userFilters.siteId) {
      changeUserFilters({
        siteId,
        groupIds: userFilters.groupIds,
        operator: userFilters.operator
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteId]);

  useEffect(() => {
    if (userFilters.siteId === undefined) {
      setValue('siteId', '');
    }
  }, [setValue, userFilters.siteId]);

  function resetSitesFilters() {
    setCookie(`GroupsElectricitySitesRankingPage-${organizationId}`, null);
  }

  function handleGroupIdsFilter() {
    resetSitesFilters();

    changeUserFilters({ groupIds, operator, siteId: userFilters.siteId });
  }

  function handleOperatorChange(newOperator) {
    setOperator(newOperator);
  }

  return (
    <div className="text-left pb-2">
      {filterTab === 0 && (
        <SitesFormSingleSelect
          selectStyle={{
            borderRadius: '8px'
          }}
          autoFocus
          menuWidth={360}
          autoSelect
          direction={isMobile ? 'bottom' : 'right'}
          mode="simpleSelect"
          formProps={formProps}
          control={control}
          label={t('sidebar.filter.site.label')}
          tagPlaceholder={t('sidebar.filter.site.tag_placeholder')}
          name="siteId"
          placeholder={t('sidebar.filter.site.placeholder')}
          data-testid="sidebar-filter-site"
        />
      )}
      {filterTab === 1 && (
        <GroupsFormMultiSelect
          withTagsMenu
          selectStyle={{
            borderRadius: '8px'
          }}
          autoFocus
          menuWidth={360}
          badge
          control={control}
          label={t('sidebar.filter.groups.label')}
          name="groupIds"
          placeholder={<Badge label={t('sidebar.filter.groups.placeholder.badge')} />}
          shrink
          withSelectAll={false}
          tagPlaceholder={t('sidebar.filter.groups.tag_placeholder')}
          onSubmit={handleGroupIdsFilter}
          onOperatorChange={handleOperatorChange}
          operator={operator}
          operatorButtons={[
            {
              label: t(operators[AND_OPERATOR].label),
              value: operators[AND_OPERATOR].value
            },
            {
              label: t(operators[OR_OPERATOR].label),
              value: operators[OR_OPERATOR].value
            }
          ]}
          formProps={formProps}
          data-testid="sidebar-filter-groups"
        />
      )}
    </div>
  );
}

SidebarFilter.propTypes = {
  filterTab: PropTypes.number
};
