import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '@mui/material';

import { Badge } from 'eficia/components/atoms/Badge';

import { WrapperTag } from './Tag.style';

export default function Tag({ tagId, label, onRemove, badge, dataTestId, ...rest }) {
  const handleRemove = () => {
    onRemove(tagId, false);
  };

  if (badge)
    return (
      <Badge
        label={
          <>
            {label}
            {onRemove && tagId && (
              <Link
                className="ml-1"
                style={{ color: 'var(--text-secondary)' }}
                onClick={handleRemove}
                data-testid={dataTestId ? `${dataTestId}-remove-tag` : undefined}
              >
                <i className="fa-solid fa-circle-xmark" />
              </Link>
            )}
          </>
        }
        data-testid={dataTestId ? `${dataTestId}-tag` : undefined}
        {...rest}
      />
    );

  return (
    <WrapperTag data-testid={dataTestId ? `${dataTestId}-tag` : undefined} {...rest}>
      {label}
      {onRemove && (
        <Link
          className="ml-1"
          onClick={handleRemove}
          data-testid={dataTestId ? `${dataTestId}-remove-tag` : undefined}
        >
          <i className="fa-solid fa-circle-xmark" />
        </Link>
      )}
    </WrapperTag>
  );
}

Tag.propTypes = {
  /** badge looking */
  badge: PropTypes.bool.isRequired,
  /** tag id */
  tagId: PropTypes.string,
  /** remove tag from multiselect */
  onRemove: PropTypes.func,
  /** Text to display */
  label: PropTypes.string.isRequired,
  dataTestId: PropTypes.string
};
