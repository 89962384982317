import styled from 'styled-components';

export const MenuItemIcon = styled.div`
  margin-right: 8px;

  svg {
    fill: var(--text-body);
    color: var(--text-body);
    width: 14px !important;
  }
`;

export const MenuItemText = styled.span`
  color: var(--text-body);
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
`;
