import { useCallback } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { LOGIN_PATH } from 'eficia/constants/paths';
import useActiveOrganization from 'eficia/hooks/useActiveOrganization';
import eficiaClient from 'eficia/services/eficiaClient';

export default function useLogout() {
  const client = eficiaClient();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { changeActiveOrganizationId } = useActiveOrganization();

  const fetcher = useCallback(() => {
    return client.user.logout();
  }, [client.user]);

  const mutation = useMutation(fetcher, {
    onSuccess: (response) => {
      const { data } = response;

      queryClient.clear();

      // Forcer à se connecter de tous les autres onglets
      // Mais uniquement dès qu'un onglet est actif à nouveau (éviter de DDoS le Back d'un coup si beaucoup d'onglets)
      changeActiveOrganizationId();

      if (data?.data?.redirect_url) {
        window.location.replace(data?.data?.redirect_url);
      } else {
        navigate(LOGIN_PATH);
      }
    },
    onError: () => {}
  });
  return mutation.mutate;
}
