import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Checkbox as CheckboxMui, Radio } from '@mui/material';

export const refUpdater =
  ({ checked, indeterminate }) =>
  (input) => {
    if (input) {
      input.checked = checked;
      input.indeterminate = indeterminate;
    }
  };

class Checkbox extends PureComponent {
  handleCheckboxClick = (e) => {
    const { onChange } = this.props;
    onChange && onChange(e);
  };

  render() {
    const { checked, indeterminate = false, disabled, readOnly, mode, ...rest } = this.props;

    const isDisabled = disabled || readOnly;

    const Element = mode === 'simpleSelect' ? Radio : CheckboxMui;

    return (
      <div style={{ transform: 'scale(0.85)' }}>
        <Element
          disableRipple
          sx={{
            '&:hover': { backgroundColor: 'transparent' },
            svg: { color: 'var(--border-action-hi)' }
          }}
          inputProps={{
            onClick: this.handleCheckboxClick,
            type: mode === 'simpleSelect' ? 'radio' : 'checkbox',
            ref: refUpdater({ checked, indeterminate }),
            disabled: isDisabled,
            ...rest
          }}
          checked={checked}
        />
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  mode: PropTypes.oneOf(['multiSelect', 'simpleSelect'])
};

export default Checkbox;
