import styled from 'styled-components';

import { Menu } from '@mui/material';

export const MenuWrapper = styled(Menu)`
  &.menu-wrapper {
    .MuiMenu-paper {
      box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.08) !important;
      margin-top: 4px;
    }

    .MuiList-root {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
      min-width: 200px;
    }

    .MuiListItem-root {
      cursor: pointer;
      padding: 9px 16px;

      &:hover * {
        fill: var(--primary);
        color: var(--primary);
      }
    }

    a {
      color: var(--text-body);
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
    }
  }
`;
